<template>
  <div class="blank_asset3">
    <div class="area_view">
      <div class="head_tbl">
        <div class="pos_left">
          <strong class="tit_text">계약/발주 정보</strong>
        </div>
      </div>
      <div class="tbl_comm tbl_view">
        <table>
          <colgroup>
            <col style="width: 171px" />
            <col />
            <col style="width: 171px" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>계약정보</th>
              <td>
                <ConnectViewOnlyOne
                  :dataList="certificateItemData.contract"
                  titleKeyName="description"
                  @onClickConnect="onClickConnectContract"
                />
              </td>
              <th>발주정보</th>
              <td>
                <ConnectViewOnlyOne
                  :dataList="certificateItemData.order"
                  @onClickConnect="onClickConnectOrder"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="area_view">
      <div class="head_tbl">
        <div class="pos_left">
          <strong class="tit_text">검수 정보</strong>
        </div>
      </div>
      <div class="tbl_comm tbl_view">
        <table>
          <colgroup>
            <col style="width: 171px" />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>검수내역</th>
              <td colspan="3">
                <ConnectView
                  v-if="certificateItemData.contractInspect.length > 0"
                  :dataList="certificateItemData.contractInspect"
                  titleKeyName="calculateNum"
                  :disabledData="{ aprvlStatus: '-' }"
                  @onClickConnect="(item) => $emit('onClickPopInspect', item)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="tbl_comm tbl_list">
        <table>
          <colgroup>
            <col style="width: 140px" />
            <!-- No. -->
            <col style="width: 140px" />
            <!-- 정산 ID -->
            <col style="width: 140px" />
            <!-- 검수예정일 -->
            <col style="width: 140px" />
            <!-- 검수일 -->
            <col style="width: 140px" />
            <!-- 금번 검수 금액 -->
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>정산 ID</th>
              <th>검수예정일</th>
              <th>검수일</th>
              <th>금번 검수 금액</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in inspectHistory" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.calculateNum }}</td>
              <td>{{ item.calculateDate | dateStringFormat }}</td>
              <td>{{ item.inspectCompleteDate }}</td>
              <td>{{ item.inspectPrice }}</td>
            </tr>
            <tr v-if="inspectHistory.length < 1">
              <td colspan="5">검수 리스트가 없습니다.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="tbl_foot">
        <div class="area_total">
          <dl>
            <dt>총 검수금액</dt>
            <dd>
              {{ totalInspectPrice | currency
              }}<span class="util_total">{{ certificateItemData.payment.currency }}</span>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectViewOnlyOne from "@/components/common/connect/ConnectViewOnlyOne";
import ConnectView from "@/components/common/connect/ConnectView";

export default {
  name: "StatmentOtherMenuInfo",
  components: {
    ConnectViewOnlyOne,
    ConnectView,
  },
  props: {
    certificateItemData: Object,
    inspectHistory: Array,
  },
  computed: {
    totalInspectPrice() {
      if (
        !this.certificateItemData.inspectHistory ||
        this.certificateItemData.inspectHistory.length < 1
      )
        return "0";
      let result = 0;
      this.certificateItemData.inspectHistory.forEach((inspect) => {
        result += Number(inspect.inspectPrice);
      });
      return result;
    },
  },
  methods: {
    // 계약정보 항목 클릭시
    onClickConnectContract(item) {},
    // 발주정보 항목 클릭시
    onClickConnectOrder(item) {},
  },
};
</script>
